export const cdn = (
  src: string,
  options?: {
    w?: number | string,
    h?: number | string,
    q?: number
  }
) => {
  const quality = import.meta.env.VITE_CDN_QUALITY || 70;
  const host = import.meta.env.VITE_APP_ENV === 'production' ? 'https://olagg.io' : 'https://staging.olagg.io';
  const dpr = (window.devicePixelRatio || 1).toFixed(1);
  const ops = `dpr=${dpr},quality=${options?.q || quality}${options?.w ? `,width=${options.w}` : ''}${options?.h ? `,height=${options.h}` : ''}`;

  return `${host}/cdn-cgi/image/${ops}/${src}`;
};
