import { LoginWithProviderEndpoint, fetcher, Endpoint, Method, ReferralsEndpoints } from "@olagg/api-hooks";
import { signInWithFacebookProvider, signInWithGoogleProvider } from "@olagg/plugins/firebase";
import { signInWithDiscordProvider, signInWithTwitterProvider } from "@olagg/plugins/supabase";
import { useAuthStore, useOnboardingStore } from "@olagg/store";
import Cookies from "js-cookie";
import { useContext, useRef } from "react"
import { useSearchParams } from "react-router-dom"
import { analytics } from "../../../analytics";
import {
  create,
  get,
  parseCreationOptionsFromJSON,
  parseRequestOptionsFromJSON,
  supported,
} from "@github/webauthn-json/browser-ponyfill";
import { AdTrackingContext } from '@/contexts/adTrackingContext';
import i18n from "@/i18n";

const REFER_FRIEND = '1';

const useAuth = () => {
  const { fcb, fbp } = useContext(AdTrackingContext)
  const { closeOnboarding } = useOnboardingStore()
  const { me, getMe } = useAuthStore()
  const [searchParams] = useSearchParams()
  const referrerId = searchParams.get('referrerId') || localStorage.getItem('referrerId')
  const redirect = useRef<any>()
  const [referredAction, referredId] = referrerId?.split('T') || []; // destruct referrerId into relevant action id and referred user id

  const abortController = new AbortController();

  const initRedirectPath = () => {
    redirect.current = searchParams.get('redirect') || localStorage.getItem('redirect')
    if (redirect.current) localStorage.setItem('redirect', redirect.current)
  }

  const persistUserToken = (token: any) => {
    const cookieOptions = {
      expires: 365,
      domain: import.meta.env.DEV ? 'localhost' : '.olagg.io'
    }
    Cookies.set('auth._token.local', token, cookieOptions)
  }

  const triggerAnalyticsAfterLogin = (provider: string, user?: any) => {
    const currentUser = me || user
    let props = {
      identity_provider: provider,
      user_agent: navigator.userAgent
    }
    if (fcb != null) props.fbc = fcb
    if (fbp != null) props.fbp = fbp
    analytics()
      ?.track('User SignedIn', props)

    analytics()
      ?.identify(`${currentUser.id}`, {
        email: currentUser.email,
        name: currentUser.name,
        google_id: currentUser.authProviderData?.hasOwnProperty('google') ? currentUser.authProviderData.google.id : '',
        discord_id: currentUser.authProviderData?.hasOwnProperty('discord') ? currentUser.authProviderData.discord.id : '',
        game_categories: currentUser.gameCategories,
        opportunities: currentUser?.opportunities,
        level: currentUser.level?.value,
        xp: currentUser.level?.score,
        identity_provider: provider,
        user_agent: navigator.userAgent
      })
  }

  const validateReferFriend = (newAccount: boolean) => {
    // validate only if it's a new account, the action is refer friend and the referred user id was sent
    if (newAccount && referrerId && referredId && referredAction === REFER_FRIEND) {
      fetcher(ReferralsEndpoints.validateAction(referrerId))
        .then(() => localStorage.removeItem('referrerId'))
        .catch((e) => console.log(e))
    };
  }

  const handleUserToken = async (response: any, provider) => {
    persistUserToken(response?.token);
    validateReferFriend(response?.new_account);
    const user = await getMe()
    triggerAnalyticsAfterLogin(provider, user)
    return user
  }

  const getUserToken = async (provider: string, data: any) =>
    await fetcher(LoginWithProviderEndpoint[provider]({
      ...data,
      provider,
      referrerId: referredAction === REFER_FRIEND ? referredId : undefined
    }))
      .then((response) => handleUserToken(response, provider))

  const validateRegistration = async (payload) => {
    const options = parseCreationOptionsFromJSON({ publicKey: payload });
    const response = await create(options);

    const callback = {
      userAttributes: payload.user,
      publicKeyCredential: response.toJSON(),
      challenge: payload.challenge,
      credentialUsername: payload.user.displayName
    }

    const resp = await fetcher({
      path: '/auth/passkeys/registration/callback',
      method: Method.POST,
      body: callback,
    } as Endpoint<any>);

    return resp
  }

  const loginWithProvider = async (provider: string) => {
    try {
      initRedirectPath()
      let response;
      switch (provider) {
        case 'google':
          response = await signInWithGoogleProvider()
          break;
        case 'facebook':
          response = await signInWithFacebookProvider()
          break;
        case 'twitter':
          response = await signInWithTwitterProvider()
          break;
        case 'discord':
          response = await signInWithDiscordProvider()
          break;
        default:
          console.log('Provider not found')
      }
      await getUserToken(provider, response.credential);
      closeOnboarding()
    } catch (error) {
    }
  }

  const generateChallenge = async (email?: string) => fetcher({
    path: '/auth/challenge',
    method: Method.POST,
    body: { email, lang: i18n.language },
  } as Endpoint<any>);

  const sendAuthCode = async (email: string) =>
    fetcher({
      path: '/auth/code',
      method: Method.POST,
      body: { email, referrerId: referredAction === REFER_FRIEND ? referredId : undefined, lang: i18n.language },
    } as Endpoint<any>);

  const validateAuthCode = async (email: string, code: string) => fetcher({
    path: '/auth/code/validate',
    method: Method.POST,
    body: { email, code, lang: i18n.language },
  } as Endpoint<any>);


  const autoFill = async () => {
    if (supported()) {
      const publicKey = await generateChallenge()
      const credentials = parseRequestOptionsFromJSON({
        publicKey: publicKey.options,
        signal: abortController.signal,
      })
      // @ts-ignore
      credentials.mediation = 'conditional'

      console.log('credentials', credentials)

      const response = await get(credentials)

      console.log(response.response)

      return fetcher({
        path: '/auth/passkeys/authentication/callback',
        method: Method.POST,
        body: {
          publicKeyCredential: response.toJSON(),
          challenge: publicKey.options.challenge,
          id: response.id,
          lang: i18n.language
        },
      } as Endpoint<any>);
    }
  }

  return {
    autoFill,
    loginWithProvider,
    generateChallenge,
    sendAuthCode,
    validateAuthCode,
    validateRegistration,
    handleUserToken,
    abortController,
    validateReferFriend
  }
}

export default useAuth;
